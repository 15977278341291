






































import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import PartnerCard from '@/components/marketplace/PartnerCard.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';

import partners from './partners.json';
@Component({
  components: {
    PartnerCard,
    UiPagination,
    UiTextEdit,
  },
})
export default class Marketplace extends BaseVue {
  public categories = [
    'All',
    'Blockchain Protocols',
    'Exchanges & Custody',
    'ERP / GL',
    '1099',
    'Payments Prcoessing',
    'Staking',
    'DeFi',
    'Compliance',
    'Banking',
    'Tax Software',
    'Token Management',
  ];

  public selectedCategory = 'All';
  public itemsPerPage = 12;
  public page = 1;
  public search = '';

  public getTabClass(category: string) {
    if (category === this.selectedCategory) {
      return 'tw-bg-neutral-400 tw-text-neutral-100 tw-px-3 tw-py-2 tw-font-bold tw-text-sm tw-rounded';
    } else {
      return 'tw-text-gray-500 hover:tw-text-gray-700 tw-px-3 tw-py-2 tw-font-medium tw-text-sm';
    }
  }

  public get pagePartners() {
    return this.filteredPartners.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);
  }

  public get filteredPartners() {
    let res = partners;
    if (this.selectedCategory !== 'All') res = res.filter((partner) => partner.category === this.selectedCategory);
    if (this.search)
      res = res.filter((partner) => {
        return (
          partner.name.toLowerCase().includes(this.search.toLowerCase()) ||
          partner.description.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    return res;
  }

  public onSelectCategory(category: string) {
    this.selectedCategory = category;
    this.page = 1;
  }
}
