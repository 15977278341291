<template>
  <div v-if="$store.state.currentOrg">
    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-layout row wrap style="padding: 1em">
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs12>
                <v-tabs color="transparent">
                  <v-tab> explorer </v-tab>
                  <v-tab-item class="pa-1 pt-1">
                    <v-flex xs12>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <div class="tw-flex tw-h-12 tw-w-hull tw-my-2.5">
                            <div
                              class="tw-flex tw-flex-1 tw-items-center tw-h-full tw-rounded tw-px-4 tw-border tw-border-gray-500"
                            >
                              <input
                                placeholder="Address (0x...)"
                                class="tw-flex-1 tw-outline-none tw-focus:outline-none"
                                id="addressInput"
                              />
                            </div>
                          </div>
                          <div class="tw-flex tw-h-12 tw-w-hull tw-my-2.5">
                            <v-select
                              v-model="viewNetworkId"
                              class="tw-flex-1 tw-outline-none tw-focus:outline-none"
                              :items="networks().map((m) => m.toUpperCase())"
                              :label="'Network ID'"
                            />
                            <v-btn @click="showDetailModalForInput">Check Details</v-btn>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-tab-item>
                </v-tabs>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md6 style="padding: 1em">
        <v-layout>
          <v-flex xs12>
            <v-tabs color="transparent">
              <v-tab :key="99"> Sync Configuration </v-tab>
              <v-tab v-for="(rule, index) in ruleHeaders" :key="index">
                {{ rule.name }}
              </v-tab>

              <v-tab-item :key="99">
                <v-card class="elevation-3">
                  <div>
                    <v-flex offset-sm1 class="pt-3">
                      <v-card-title primary-title><h3 class="headline mb-0">Sync Configuration</h3></v-card-title>
                      <v-layout wrap align-center>
                        <v-flex
                          xs12
                          sm12
                          md6
                          class="pt-3"
                          v-for="(erc20Rule, index) in networkErc20SpamFiltering"
                          :key="index"
                        >
                          <v-switch
                            :label="`Filter ${erc20Rule.networkId.toUpperCase()} ERC-20s by Spam Score`"
                            v-model="erc20Rule.filterBySpamScoreEnabled"
                            @change="updateSpamScore(erc20Rule.networkId, erc20Rule.filterBySpamScoreEnabled)"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm12 md6 class="pt-3"> </v-flex>
                      </v-layout>
                      <v-card-title primary-title
                        ><h3 class="headline mb-0">Wallet Level Options (Polygon)</h3></v-card-title
                      >
                      <v-data-table
                        :headers="walletHeaders"
                        :items="walletConfiguration()"
                        :key="loadingKey"
                        class="elevation-1"
                        style="padding: 0 5px 0 14px; text-overflow: ellipsis; overflow: hidden; width: 92%"
                        :rows-per-page-items="pageRows"
                      >
                        <template slot="items" slot-scope="props">
                          <tr>
                            <td>
                              <b
                                class="text pl-2 pt-6 ma-2"
                                style="
                                  padding: 0 5px 0 14px;
                                  text-overflow: ellipsis;
                                  overflow: hidden;
                                  width: 100%;
                                  max-width: 90px;
                                "
                              >
                                {{ props.item.walletName }}
                              </b>
                            </td>
                            <td>
                              <b
                                class="text pl-2 pt-6 ma-2"
                                style="
                                  padding: 0 5px 0 14px;
                                  text-overflow: ellipsis;
                                  overflow: hidden;
                                  width: 100%;
                                  max-width: 90px;
                                "
                              >
                                {{ props.item.networkId.toUpperCase() }}
                              </b>
                            </td>
                            <td>
                              <v-switch
                                class="text align-right"
                                style="
                                  padding: 0 5px 0 14px;
                                  text-overflow: ellipsis;
                                  overflow: hidden;
                                  width: 100%;
                                  max-width: 200px;
                                "
                                v-model="props.item.syncAllLogEvents"
                                @click="updateWalletSyncOptions(props.item.walletId, !props.item.syncAllLogEvents)"
                              >
                              </v-switch>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item v-for="(rule, index) in ruleHeaders" :key="index" class="pa-1">
                <v-card class="elevation-3">
                  <div>
                    <v-data-table
                      :headers="listHeaders"
                      :items="rule.itemGetter"
                      :key="loadingKey"
                      class="elevation-1"
                      :rows-per-page-items="pageRows"
                    >
                      <template slot="items" slot-scope="props">
                        <tr>
                          <td>
                            <b
                              class="text pl-2 pt-6 ma-2"
                              style="
                                padding: 0 5px 0 14px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                width: 100%;
                                max-width: 90px;
                              "
                            >
                              {{ props.item.address }}
                            </b>
                          </td>
                          <td>
                            <b
                              class="text pl-2 pt-6 ma-2"
                              style="
                                padding: 0 5px 0 14px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                width: 100%;
                                max-width: 90px;
                              "
                            >
                              {{ props.item.networkId.toUpperCase() }}
                            </b>
                          </td>
                          <td>
                            <v-btn
                              class="text align-right"
                              style="
                                padding: 0 5px 0 14px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                width: 100%;
                                max-width: 200px;
                              "
                              @click="showDetailModal(props.item.address, props.item.networkId)"
                            >
                              View Address Details
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- Address Details Modal -->
      <v-container fluid grid-list-xl>
        <v-dialog
          v-model="viewDialog"
          width="1100"
          persistent
          :loading="isLoading"
          class="white--text"
          color="purple darken-2"
        >
          <v-card>
            <v-card-title class="text-xs-right">
              <v-layout>
                <v-flex xs1 offset-xs11>
                  <v-icon @click="closeDetails" class="text-xs-right">close</v-icon>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-flex offset-sm1 class="pt-3">
              <v-layout wrap align-center>
                <v-flex xs12 sm12 md6 class="pt-3">
                  Address: <b>{{ viewAddress }}</b>
                </v-flex>
                <v-flex xs12 sm12 md6 class="pt-3">
                  Ticker: <b>{{ contractDetails.ticker }}</b>
                </v-flex>
                <v-flex xs12 sm12 md6 class="pt-3">
                  Name: <b>{{ contractDetails.name }}</b>
                </v-flex>
                <v-flex xs12 sm12 md6 class="pt-3">
                  Type: <b>{{ contractDetails.type }}</b>
                </v-flex>
                <v-flex xs12 sm12 md6 class="pt-3">
                  Decimals: <b>{{ contractDetails.decimals }}</b>
                </v-flex>
                <v-flex xs12 sm12 md6 class="pt-3">
                  Bitwave Verified: <b>{{ contractDetails.verified }}</b>
                </v-flex>
                <v-flex xs12 sm12 md6 class="pt-3">
                  Deployer Address: <b>{{ contractDetails.deployerAddress }}</b>
                </v-flex>
                <v-flex xs12 sm12 md6 class="pt-3">
                  Deployed Block: <b>{{ contractDetails.deployedBlock }}</b>
                </v-flex>
                <v-flex xs12 sm12 md6 class="pt-3">
                  Current Status: <b>{{ contractDetails.currentStatus }}</b>
                </v-flex>
                <v-flex xs12 sm12 md6 class="pt-3" v-if="contractDetails.spamScore !== null">
                  Spam Score: <b>{{ contractDetails.spamScore }}</b>
                </v-flex>
              </v-layout>

              <v-btn
                @click="addWhitelistAddressRule(viewAddress, viewNetworkId.toLowerCase())"
                v-if="
                  contractDetails.currentStatus !== 'Address Blacklisted' &&
                  contractDetails.currentStatus !== 'Address Whitelisted'
                "
              >
                Whitelist Address
              </v-btn>
              <v-btn
                @click="addBlacklistAddressRule(viewAddress, viewNetworkId.toLowerCase())"
                v-if="
                  contractDetails.currentStatus !== 'Address Blacklisted' &&
                  contractDetails.currentStatus !== 'Address Whitelisted'
                "
              >
                Blacklist Address
              </v-btn>
              <v-btn
                @click="purgeAddressFromRules(viewAddress, viewNetworkId.toLowerCase())"
                v-if="contractDetails.currentStatus !== 'Unlisted'"
              >
                Unlist Address
              </v-btn>
              <v-btn
                @click="addWhitelistDeployerRule(contractDetails.deployerAddress, viewNetworkId.toLowerCase())"
                v-if="
                  contractDetails.currentStatus !== 'Deployer Whitelisted' &&
                  contractDetails.currentStatus !== 'Deployer Blacklisted'
                "
              >
                Whitelist Deployer
              </v-btn>
              <v-btn
                @click="addBlacklistDeployerRule(contractDetails.deployerAddress, viewNetworkId.toLowerCase())"
                v-if="
                  contractDetails.currentStatus !== 'Deployer Whitelisted' &&
                  contractDetails.currentStatus !== 'Deployer Blacklisted'
                "
              >
                Blacklist Deployer
              </v-btn>
              <v-flex xs12 v-if="isLoading">
                <v-progress-linear indeterminate color="green" />
              </v-flex>
            </v-flex>
            <v-card-title class="text-xs-right"> </v-card-title>
          </v-card>
        </v-dialog>
      </v-container>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios';
import gql from 'graphql-tag';
import Web3 from 'web3';

import { baConfig } from '../../../config';

export default {
  name: 'OrgTokenFiltering',
  data() {
    return {
      ruleHeaders: [
        { id: 't-whitelist', name: 'Token Whitelist', itemGetter: this.tWhitelist() },
        { id: 't-blacklist', name: 'Token Blacklist', itemGetter: this.tBlacklist() },
        { id: 'd-whitelist', name: 'Deployer Whitelist', itemGetter: this.dWhitelist() },
        { id: 'd-blacklist', name: 'Deployer Blacklist', itemGetter: this.dBlacklist() },
        { id: 'auto-filtered', name: 'Filtered Tokens', itemGetter: this.autoFilteredList() },
      ],
      searchKeyword: '',
      listHeaders: [
        {
          text: 'Address',
          align: 'start',
          sortable: true,
          value: 'address',
        },
        {
          text: 'Network ID',
          sortable: true,
          value: 'networkId',
        },
        {
          text: 'Token Details',
          sortable: false,
        },
      ],
      walletHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'walletName',
        },
        {
          text: 'Network',
          sortable: false,
        },
        {
          text: 'Deep Syncing Enabled',
          sortable: false,
        },
      ],
      viewDialog: false,
      viewAddress: null,
      viewNetworkId: null,
      contractDetails: {
        ticker: null,
        name: null,
        deployerAddress: null,
        deployedBlock: null,
        type: null,
        decimals: null,
        verified: null,
        currentStatus: null,
        spamScore: null,
      },
      pageRows: [10, 50, 100, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }],
      isLoading: false,
      loadingKey: 0,
      networkErc20SpamFiltering: [
        {
          networkId: '',
          filterBySpamScoreEnabled: false,
        },
      ],
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    networks() {
      return Array.from(this.$store.state.tokenFilteringRules.keys());
    },
    rules(networkId) {
      return this.$store.state.tokenFilteringRules.get(networkId);
    },
    walletConfiguration() {
      // this is only relevant for polygon right now.
      const wallets = (this.$store.state.wallets.wallets ?? []).filter((w) => w.type === 8);
      const mappedWallets = wallets.map((m) => {
        const walletPrefs = (this.rules('polygon').walletSyncPreferences ?? []).find((p) => p.walletId === m.id);
        return {
          walletId: m.id,
          walletName: m.name,
          networkId: 'polygon',
          syncAllLogEvents: walletPrefs ? walletPrefs.syncAllLogEvents : false,
        };
      });
      return mappedWallets;
    },
    tWhitelist() {
      const ret = this.networks().flatMap((n) => {
        return (this.rules(n).allowedContractAddresses ?? []).map((a) => {
          return {
            networkId: n,
            address: a,
          };
        });
      });
      return ret;
    },
    tBlacklist() {
      const ret = this.networks().flatMap((n) => {
        return (this.rules(n).disabledContractAddresses ?? []).map((a) => {
          return {
            networkId: n,
            address: a,
          };
        });
      });
      return ret;
    },
    dWhitelist() {
      const ret = this.networks().flatMap((n) => {
        return (this.rules(n).allowedDeployedByAddresses ?? []).map((a) => {
          return {
            networkId: n,
            address: a,
          };
        });
      });
      return ret;
    },
    dBlacklist() {
      const ret = this.networks().flatMap((n) => {
        return (this.rules(n).disabledDeployedByAddresses ?? []).map((a) => {
          return {
            networkId: n,
            address: a,
          };
        });
      });
      return ret;
    },
    autoFilteredList() {
      const ret = this.networks().flatMap((n) => {
        return (this.rules(n).filteredAddresses ?? []).map((a) => {
          return {
            networkId: n,
            address: a,
          };
        });
      });
      return ret;
    },
    spamScoreThreshold(networkId) {
      return this.rules(networkId).erc20FilterThreshold;
    },
    closeDetails() {
      this.viewDialog = false;
      this.viewAddress = null;
    },
    async saveConfig(networkId) {
      const existing = this.rules(networkId);
      const newFilterObject = {
        ...existing,
        erc20FilterThreshold: this.spamThreshold.toString(),
        __typename: undefined,
      };
      await this.updateOrgFilteringRules(networkId, newFilterObject);
      this.configurationUnchanged = false;
    },
    showDetailModalForInput() {
      const address = document.getElementById('addressInput').value;
      const networkId = this.viewNetworkId;
      const web3 = new Web3(baConfig.ethRpcUrl);
      if (web3.utils.isAddress(address)) {
        this.viewAddress = address;
        this.viewDialog = true;
        this.populateAddressDetails(networkId);
      } else {
        alert('Please enter a valid ethereum address');
      }
    },
    showDetailModal(address, networkId) {
      this.viewDialog = true;
      this.viewAddress = address;
      this.populateAddressDetails(networkId);
    },
    async populateAddressDetails(networkId) {
      this.contractDetails = {
        ticker: null,
        name: null,
        deployerAddress: null,
        deployedBlock: null,
        type: null,
        decimals: null,
        verified: null,
        currentStatus: null,
        spamScore: null,
      };
      this.viewNetworkId = networkId;
      this.isLoading = true;
      const resp = await axios.get(`${baConfig.addressSvcUrl}/networks/${networkId}/addresses/${this.viewAddress}`);
      if (resp && resp.status === 200) {
        this.isLoading = false;
        this.contractDetails = {
          ticker: resp.data.item.contractSymbol,
          name: resp.data.item.name,
          deployerAddress: resp.data.item.deploymentDetails.deployedByAddress,
          deployedBlock: resp.data.item.deploymentDetails.block,
          type: resp.data.item.type,
          decimals: resp.data.item.decimals,
          verified: resp.data.item.verified,
          currentStatus: this.getCurrentStatus(),
          spamScore: resp.data.item.spamScore ?? null,
        };
        return resp.data;
      } else {
        this.isLoading = false;
        throw new Error('Problem getting address details');
      }
    },
    getCurrentStatus() {
      const address = this.viewAddress;
      const networkId = this.viewNetworkId;

      const isTokenWhitelisted =
        this.tWhitelist().filter((e) => e.address === address.toLowerCase() && e.networkId === networkId).length > 0;
      if (isTokenWhitelisted) {
        return 'Address Whitelisted';
      }

      const isTokenBlacklisted =
        this.tBlacklist().filter((e) => e.address === address.toLowerCase() && e.networkId === networkId).length > 0;
      if (isTokenBlacklisted) {
        return 'Address Blacklisted';
      }

      const isDeployerWhitelisted =
        this.dWhitelist().filter((e) => e.address === address.toLowerCase() && e.networkId === networkId).length > 0;
      if (isDeployerWhitelisted) {
        return 'Deployer Whitelisted';
      }

      const isDeployerBlacklisted =
        this.dBlacklist().filter((e) => e.address === address.toLowerCase() && e.networkId === networkId).length > 0;
      if (isDeployerBlacklisted) {
        return 'Deployer Blacklisted';
      }

      return 'Unlisted';
    },
    async addWhitelistAddressRule(address, networkId) {
      const isTokenWhitelisted =
        this.tWhitelist().filter((e) => e.address === address.toLowerCase() && e.networkId === networkId).length > 0;
      if (!isTokenWhitelisted) {
        const existing = this.rules(networkId);
        const newArray = existing.allowedContractAddresses.concat([address.toLowerCase()]);
        const newFilterObject = { ...existing, allowedContractAddresses: newArray, __typename: undefined };
        await this.updateOrgFilteringRules(newFilterObject, networkId);
      } else {
        alert('Address already added to whitelist');
      }
    },
    async addBlacklistAddressRule(address, networkId) {
      const isTokenBlacklisted =
        this.tBlacklist().filter((e) => e.address === address.toLowerCase() && e.networkId === networkId).length > 0;
      if (!isTokenBlacklisted) {
        const existing = this.rules(networkId);
        const newArray = existing.disabledContractAddresses.concat([address.toLowerCase()]);
        const newFilterObject = { ...existing, disabledContractAddresses: newArray, __typename: undefined };
        await this.updateOrgFilteringRules(newFilterObject, networkId);
      } else {
        alert('Address already added to whitelist');
      }
    },
    async addWhitelistDeployerRule(address, networkId) {
      const isDeployerWhitelisted =
        this.dWhitelist().filter((e) => e.address === address.toLowerCase() && e.networkId === networkId).length > 0;
      if (!isDeployerWhitelisted) {
        const existing = this.rules(networkId);
        const newArray = existing.allowedDeployedByAddresses.concat([address.toLowerCase()]);
        const newFilterObject = { ...existing, allowedDeployedByAddresses: newArray, __typename: undefined };
        await this.updateOrgFilteringRules(newFilterObject, networkId);
      } else {
        alert('Address already added to whitelist');
      }
    },
    async addBlacklistDeployerRule(address, networkId) {
      const isDeployerBlacklisted =
        this.dBlacklist().filter((e) => e.address === address.toLowerCase() && e.networkId === networkId).length > 0;
      if (!isDeployerBlacklisted) {
        const existing = this.rules(networkId);
        const newArray = existing.disabledDeployedByAddresses.concat([address.toLowerCase()]);
        const newFilterObject = { ...existing, disabledDeployedByAddresses: newArray, __typename: undefined };
        await this.updateOrgFilteringRules(newFilterObject, networkId);
      } else {
        alert('Address already added to whitelist');
      }
    },
    async purgeAddressFromRules(address, networkId) {
      const existing = this.rules(networkId);
      const purgedAllowAddressArray = (existing.allowedContractAddresses ?? []).filter(
        (f) => f.toLowerCase() !== address.toLowerCase()
      );
      const purgedAllowDeployerArray = (existing.allowedDeployedByAddresses ?? []).filter(
        (f) => f.toLowerCase() !== address.toLowerCase()
      );
      const purgedDisabledAddressArray = (existing.disabledContractAddresses ?? []).filter(
        (f) => f.toLowerCase() !== address.toLowerCase()
      );
      const purgedDisabledDeployerArray = (existing.disabledDeployedByAddresses ?? []).filter(
        (f) => f.toLowerCase() !== address.toLowerCase()
      );
      const newFilterObject = {
        ...existing,
        allowedContractAddresses: purgedAllowAddressArray,
        allowedDeployedByAddresses: purgedAllowDeployerArray,
        disabledContractAddresses: purgedDisabledAddressArray,
        disabledDeployedByAddresses: purgedDisabledDeployerArray,
        __typename: undefined,
      };
      await this.updateOrgFilteringRules(newFilterObject, networkId);
    },
    async updateSpamScore(networkId, erc20SpamFilteringEnabled) {
      const existing = this.rules(networkId);
      const spamThreshold = erc20SpamFilteringEnabled ? 0.5 : 1;
      const newFilterObject = { ...existing, erc20FilterThreshold: spamThreshold.toString(), __typename: undefined };
      await this.updateOrgFilteringRules(newFilterObject, networkId);
    },
    async updateWalletSyncOptions(walletId, expandedSyncingEnabled) {
      // this feature is only enabled on polygon right now.
      const networkId = 'polygon';
      const existing = this.rules(networkId);
      const existingWalletSyncPreferences = existing.walletSyncPreferences ?? [];
      const existingRule = existingWalletSyncPreferences.find((r) => r.walletId === walletId);
      let newWalletSyncPreferences;
      if (existingRule) {
        const filteredPrefs = existingWalletSyncPreferences.filter((r) => r.walletId !== walletId);
        filteredPrefs.push({
          walletId: walletId,
          syncAllLogEvents: expandedSyncingEnabled,
        });
        newWalletSyncPreferences = filteredPrefs;
      } else {
        newWalletSyncPreferences = existingWalletSyncPreferences.concat({
          walletId: walletId,
          syncAllLogEvents: expandedSyncingEnabled,
        });
      }
      const newFilterObject = { ...existing, walletSyncPreferences: newWalletSyncPreferences, __typename: undefined };
      await this.updateOrgFilteringRules(newFilterObject, networkId);
      this.loadingKey++;
    },
    async updateOrgFilteringRules(tokenFilteringRules, networkId) {
      this.isLoading = true;
      removeTypename(tokenFilteringRules);
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        networkId: networkId,
        details: tokenFilteringRules,
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation updateTokenFilterRule($orgId: ID!, $networkId: ID!, $details: TokenFilterRule) {
            updateTokenFilterRule(orgId: $orgId, networkId: $networkId, details: $details)
          }
        `,
        variables: vars,
      });
      this.$store.state.tokenFilteringRules.set(networkId, tokenFilteringRules);
      this.refresh();
      this.isLoading = false;
      this.viewDialog = false;
    },
    refresh() {
      this.networkErc20SpamFiltering = this.networks().map((m) => {
        const filterBySpamScoreEnabled = Number(this.rules(m).erc20FilterThreshold) < 1;
        return {
          networkId: m,
          filterBySpamScoreEnabled,
        };
      });
      this.ruleHeaders = [
        { id: 't-whitelist', name: 'Token Whitelist', itemGetter: this.tWhitelist() },
        { id: 't-blacklist', name: 'Token Blacklist', itemGetter: this.tBlacklist() },
        { id: 'd-whitelist', name: 'Deployer Whitelist', itemGetter: this.dWhitelist() },
        { id: 'd-blacklist', name: 'Deployer Blacklist', itemGetter: this.dBlacklist() },
        { id: 'auto-filtered', name: 'Filtered Tokens', itemGetter: this.autoFilteredList() },
      ];
      this.filterBySpamScoreEnabled = Number(this.spamThreshold) < 1;
    },
  },
};

function removeTypename(obj) {
  for (const prop in obj) {
    if (prop === '__typename') delete obj[prop];
    else if (typeof obj[prop] === 'object') removeTypename(obj[prop]);
  }
}
</script>
