



































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
// import { Partner } from '@/components/marketplace/types';

export interface Partner {
  name: string;
  category: string;
  logo: string;
  description: string;
  learnMoreLink: string;
}

@Component({
  components: {},
})
export default class PartnerCard extends BaseVue {
  @Prop({ required: true })
  public readonly details!: Partner;

  // you will need to update icons.ts here with the name and regular
  public get icon() {
    switch (this.details.category) {
      case 'Blockchain Protocols':
        return 'fa-regular fa-coin-blank';
      case 'Exchanges & Custody':
        return 'fa-regular fa-key-skeleton';
      case 'ERP / GL':
        return 'fa-regular fa-calculator';
      case '1099':
        return 'fa-regular fa-file';
      case 'Payments Prcoessing':
        return 'fa-regular fa-piggy-bank';
      case 'Staking':
        return 'fa-regular fa-money-bill';
      case 'DeFi':
        return 'fa-regular fa-exchange';
      case 'Compliance':
        return 'fa-regular fa-shield-halved';
      case 'Banking':
        return 'fa-brands fa-bank';
      case 'Tax Software':
        return 'fa-regular fa-receipt';
      case 'Token Management':
        return 'fa-brands fa-connectdevelop';
    }
  }
}
